.donate-card {
    width: 75vw;
    order: 0.15rem;
    border-style: solid;
    border-radius: 0.5rem;
    border-color: rgb(47, 130, 255);
  
    justify-self: center;
    
    margin-left: 12.5vw;

    margin-top: 2vh;
    text-decoration: none !important;
    background: rgb(48, 48, 48);
    animation: 1s ease-out 0s 1 slideFadeIn;
    
}

a:link {
  color: yellow;
}

a:visited {
  color: yellow;
}

a:hover {
  color: aqua;
}

a:active {
  color: aqua;
}


@keyframes slideFadeIn {
    0% {
      transform: translateX(-3%);
      opacity: 0%;
    }
    
     100% {
      transform: translateX(0);
      opacity: 100%;
    }
}