.home-title {
    float: left;
    margin-left: 10vw;

    font-size: x-large;
    text-align: left;

    animation: 1s ease-out 0s 1 slideInLeft;
}
.-webkit-scrollbar {
    width: 200px;
}
  



@keyframes slideInLeft {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
}

.home-Ukrainian {
    color: rgb(108, 123, 233);
}

.home-Student {
    color: rgb(251, 255, 0);
}

.home-sublogo {
    margin-top: -1.5rem;
 
    animation: 2s ease-out 0s 1 slideInLeft;
}

@keyframes slideInLeftLater {
    0% {
      transform: translateX(-100%);
    }
    50% {
        transform: translateX(-100%);
    } 100% {
      transform: translateX(0);
    }
}

.home-content {
    display: grid;
}

.article-area {
    display: grid;
    float:left;
    margin-top: 1vh;
    grid-template: auto / auto auto auto;
    justify-content: center;
    animation: 1s ease-out 0s 1 slideFadeIn;
    animation-fill-mode: forwards;
    

}

.article-image {

    width: 24vw;
    margin-left: 0.5vw;

}

.article-card {
    width: 25vw;
    order: 0.15rem;
    border-style: solid;
    border-radius: 0.5rem;
    border-color: rgb(47, 130, 255);
    margin-left: 4.166vw;

    
    margin-top: 5vh;
    text-decoration: none !important;
    background: rgb(48, 48, 48);
    
}

@keyframes slideFadeIn {
    0% {
      transform: translateX(-3%);
      opacity: 0%;
    }
    
     100% {
      transform: translateX(0);
      opacity: 100%;
    }
}



.article-card:hover {
    
    
    background: radial-gradient(circle, rgba(47,130,255,0.5) 0%, rgb(48, 48, 48) 100%);
    animation: 0.1s ease-in 0s 1 articleGrow;
    scale: 1.05;
}

@keyframes articleGrow {
    0% {
        scale: 1;
    }
    100% {
        scale: 1.05;
    }
}

.article-card-content {
    text-decoration: none;
    color: white;
    margin-top: -2vh;
    padding-bottom: -1vh;
}

.home-UMD {
    color: red;
}

.article-info {
    text-align: left;


}

.article-title {
    margin-top: 1rem !important;
    
}

.article-subhead {
    margin-top: 0;
    margin-bottom: -0.1rem;
    margin-left: 0.4rem;
}